import React from 'react';
import './App.css';
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';

function App() {
  return (
    <div className="flex flex-col py-24 xl:px-96 md:px-72 sm:px-48 p-4">
      <header className='flex flex-row space-x-4 w-full justify-end items-center'>
        <h2 className='hover:underline'>
          <Link to={"/"}>home</Link>
        </h2>
        <h2 className='hover:underline'>
          <Link to={"/projects"}>projects</Link>
        </h2>
        <a className='hover:underline' href='mailto:roxanne.f@mac.com' >contact</a>
        <SocialIcon as='a' url='www.linkedin.com' href='https://www.linkedin.com/in/roxy-farhad-41b4b0215/' style={{width: "35px", height: "35px"}} />
        <SocialIcon as='a' url='www.github.com' href='https://github.com/RoxyFarhad' style={{width: "35px", height: "35px"}} />
      </header>
      <div className='mt-8'>
        <h1 className='font-semibold text-xl'>Hey! I'm <span className='text-indigo-500'>Roxy Farhad!</span></h1>
        <p className='mt-4'> 
          I am an <span className='underline-offset-2 underline decoration-indigo-500 font-semibold '>Engineer</span> working in the intersection of hardware, software, and intelligent systems. 
          <br /><br />
          While I was born and raised in London, UK, I currently live in New York and work
          as a Software Engineer at <span className='underline-offset-2 underline decoration-indigo-500 font-semibold '>Viam Robotics</span>.
          <br /><br />
        
        </p>
      </div>
    </div>
  );
}

export default App;
