import React from "react";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import Project from "./Project";

function Projects() {
    return (
        <div className="flex flex-col xl:px-96 sm:px-48 p-4 py-24">
            <header className='flex flex-row space-x-4 w-full justify-end items-center'>
                <h2 className='hover:underline'>
                    <Link to={"/"}>home</Link>
                </h2>                
                <h2 className='hover:underline'>
                    <Link to={"/projects"}>projects</Link>
                </h2>
                <a className='hover:underline' href='mailto:roxanne.f@mac.com' >contact</a>
                <SocialIcon as='a' url='www.linkedin.com' href='https://www.linkedin.com/in/roxy-farhad-41b4b0215/' style={{width: "35px", height: "35px"}} />
                <SocialIcon as='a' url='www.github.com' href='https://github.com/RoxyFarhad' style={{width: "35px", height: "35px"}} />
            </header>
            <div className="mt-8"> 
            <h1 className="font-semibold text-2xl underline-offset-2 underline decoration-indigo-500 mb-2">Projects</h1>
            <p className="text-sm mb-4">Disclaimer: This does not reference commercial work - but ad-hoc projects I've done for exploration.</p>
            <div className="flex flex-col space-y-4 pt-4">
                <Project 
                    Title="Cooksbible.app" 
                    Date="11-01-23" 
                    ImageUrl="./cooksbible.png" 
                    Description={
                        <p>
                        In the past year, I dedicated myself to developing <a href="https://cooksbible.app/" className="underline-offset-2 underline decoration-indigo-500">cooksbible.app</a>, a personalized recipe-saving tool that has evolved into a fully-fledged web application.
                        <br/> <br />
                        Being a passionate cook, I frequently exchange recipes with friends. However, I often found myself misplacing those random yet enticing recipes discovered online. With this app, you can now create a digital recipe book to preserve and organize all your favorite online recipes.
                        </p>
                    } 
                />
                <Project 
                    Title="Axidraw" 
                    Date="10-21-23" 
                    ImageUrl="./axidraw.jpeg" 
                    Description={
                        <p>
                            Using Viam's modular resource regisrtry, I hacked an axidraw machine to ingest complex images and sketch them out in real-time.
                            Using <span className="underline-offset-2 underline decoration-indigo-500">OpenCV</span>, the service works by ingesting an image, detecting its lines, and converting those into a series of line segments 
                            that are then drawn in real-time by the machine. 
                            <br /><br />
                            Check out the <a className="underline-offset-2 underline decoration-indigo-500" href="https://github.com/RoxyFarhad/robodraw">Github repo</a>
                        </p>
                    } 
                />
                <Project 
                    Title="Air Quality Rover" 
                    Date="10-14-23" 
                    ImageUrl="./rover-map.png" 
                    Description={
                        <p>
                            My colleagues and I at Viam have developed a <span className="underline-offset-2 underline decoration-indigo-500">air-quality and mess detection rover</span> capabilities for our office, 
                            using Viam's <span className="underline-offset-2 underline decoration-indigo-500">data capture, cartographer, and slam services. </span>
                            The rover autonomously moves across these pathways, halting every meter to assess the air quality of the surrounding space and ensure it remains at a healthy level.
                            <br /><br />
                            For more information or if you're interested in creating a similar tool, feel free to explore this <a className="underline-offset-2 underline decoration-indigo-500" href="https://github.com/ethanlook/airbot">Github repo</a>.
                        
                        </p>
                    } 
                />
                <Project 
                    Title="UltrasonOS" 
                    Date="01-01-20" 
                    ImageUrl="./ultrasound.png" 
                    Description={
                    <p>
                        As an undergraduate, I worked on <span className="underline-offset-2 underline decoration-indigo-500">UltrasonOS</span> at Columbia's Creative Machine Lab. This was an incredible PhD 
                        led by <span className="underline-offset-2 underline decoration-indigo-500">Yazmin Feliz,</span> who was developing the <span className="underline-offset-2 underline decoration-indigo-500">first open-source portable ultrasound device</span>. As part of this project, 
                        my team of Computer Science Engineers built the pipeline that read signals from the device and realized a 3D medical image 
                        of the object being scanned in real-time.
                        <br />< br/> 
                        Check out the <a className="underline-offset-2 underline decoration-indigo-500" href="https://academiccommons.columbia.edu/doi/10.7916/d8-6vr9-3f26">dissertation</a>
                    </p>
                } />
            </div>
            </div>
        </div>
    )
}

export default Projects;
