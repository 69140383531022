import React from "react";

interface ProjectProps {
    Title: string
    Date: string
    ImageUrl: string
    Description: React.ReactElement // this is some form of P element
}


function Project({Title, Date, ImageUrl, Description}: ProjectProps) {
    return (
        <div className="w-full flex-row flex h-72 border-2 overflow-scroll">
            <img className="w-1/3 object-cover object-center" alt="" src={ImageUrl}/>
            <div className="flex flex-col w-full px-2 py-2 space-y-2">
                <div className="flex flex-row justify-between w-full">
                    <h1 className="font-semibold text-xl underline-offset-2 underline decoration-indigo-500">{Title}</h1>
                    <h1 className="text-sm font-medium">{Date}</h1>    
                </div>
                {Description}
            </div>
        </div>
    )
}

export default Project;
